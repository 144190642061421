import { createAction } from 'typesafe-actions';
import { ToastPayload } from 'types/types';
import { CartAddressInterface, DismissableNotification } from 'types/types';

export const openMenu = createAction('@global/OPEN_MENU')<string>();
export const openDrawer = createAction('@global/OPEN_DRAWER')<string>();
export const closeMenu = createAction('@global/CLOSE_MENU')();
export const closeDrawer = createAction('@global/CLOSE_DRAWER')();
export const dismissMessagePopup = createAction(
  '@global/DISMISS_MESSAGE_POPUP'
)<string>();
export const addToast = createAction('@global/ADD_TOAST')<ToastPayload>();
export const removeToast = createAction('@global/REMOVE_TOAST')<number>();

export const setCartIsLoading = createAction(
  '@global/SET_CART_IS_LOADING'
)<boolean>();
export const setHasSyncedOutdatedCart = createAction(
  '@global/SET_HAS_SYNCED_OUTDATED_CART'
)();

export const setLastShippingAddress = createAction(
  '@global/SET_LAST_SHIPPING_ADDRESS'
)<CartAddressInterface>();
export const removeLastShippingAddress = createAction(
  '@global/REMOVE_LAST_SHIPPING_ADDRESS'
)();

export const addDismissableNotification = createAction(
  '@global/ADD_DISMISSABLE_NOTIFICATION'
)<DismissableNotification>();
export const removeDismissableNotification = createAction(
  '@global/REMOVE_DISMISSABLE_NOTIFICATION'
)<string>();

export const addLoginMessage = createAction(
  '@global/ADD_LOGIN_MESSAGE'
)<string>();
export const removeLoginMessage = createAction(
  '@global/REMOVE_LOGIN_MESSAGE'
)();

export const setIsCategoryBarVisible = createAction(
  '@global/SET_IS_CATEGORY_BAR_VISIBLE'
)<boolean>();
